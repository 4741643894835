// vendors
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function AprilPictogram({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 137.89 160.46"
      className={classNames(['fill-current', className])}
    >
      <path d="M4.83,75.25H4.7a1.62,1.62,0,0,1-1.48-1.72c2.72-36.7,27.51-66,59-69.77a1.6,1.6,0,0,1,.38,3.18C32.64,10.5,9,38.6,6.42,73.76A1.6,1.6,0,0,1,4.83,75.25Z" />
      <path d="M133.06,75.25a1.61,1.61,0,0,1-1.6-1.49C128.86,38.6,105.25,10.5,75.34,6.94a1.59,1.59,0,0,1-1.4-1.78,1.63,1.63,0,0,1,1.78-1.4C107.16,7.49,132,36.83,134.67,73.53a1.62,1.62,0,0,1-1.48,1.72Z" />
      <path d="M62.36,156.72l-.19,0C30.74,153,5.94,123.63,3.22,86.93a1.6,1.6,0,1,1,3.2-.23C9,121.86,32.64,150,62.55,153.52a1.61,1.61,0,0,1-.19,3.2Z" />
      <path d="M75.53,156.72a1.61,1.61,0,0,1-.19-3.2c29.91-3.56,53.52-31.66,56.12-66.82a1.61,1.61,0,1,1,3.21.23c-2.72,36.7-27.52,66-58.95,69.77Z" />
      <path d="M49.15,81.26l15.48-8.11v.44c-5.9,4.72-9,10-9,18.44a27.56,27.56,0,0,0,3.09,13.26c2.21,3.84,4.72,5.76,7.82,5.61v.29c-4,4.13-8.55,7.08-13.13,7.08C43,118.27,35.58,110,35.58,99.4,35.58,90.7,39.56,86.42,49.15,81.26Z" />
      <path d="M62.12,42.78v.29c-1,1.33-2.8,5.16-5.45,11.5C54.31,60.47,52.24,62,48.26,62c-3.53,0-7.37-2.81-9.73-6.93C41.92,50.44,50.47,44.55,62.12,42.78Z" />
      <path d="M72.89,58.85c0-8.11-.89-14-2.51-16.37v-.29C87.48,42.34,94,49,94,59.44v43.35c0,4.57,2.07,6.78,6,6.78a7.89,7.89,0,0,0,3-.44v.29c-5.46,5.46-13.42,8.85-19.76,8.85-6.93,0-10.32-4.42-10.32-13.27Z" />
    </svg>
  );
}

AprilPictogram.propTypes = {
  className: PropTypes.string,
};

AprilPictogram.defaultProps = {
  className: null,
};

export default AprilPictogram;
