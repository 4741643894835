// vendors
import classNames from 'classnames';
import { m as motion, useAnimation } from 'framer-motion';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import mainNavigationItems from '../../data/mainNavigationItem.json';
import WaveButton from '../Button/WaveButton';
import AubergeLogotype from '../Logo/AubergeLogotype';
import LangugageSwitcher from './LanguageSwitcher';
import * as styles from './SiteHeader.module.css';

function SiteHeader({ alternativesLanguages }) {
  const intl = useIntl();
  const controls = useAnimation();

  const [isOpen, setIsOpen] = React.useState(false);

  const menuItems = React.useMemo(
    () => mainNavigationItems[intl.locale],
    [intl.locale]
  );

  const handleButtonClick = () => {
    setIsOpen((prev) => !prev);

    controls.start({ x: isOpen ? 0 : '-100%' });
  };

  const handleLinkClick = () => {
    setIsOpen(false);

    controls.start({ x: 0 });
  };

  return (
    <header className="overflow-hidden fixed top-0 z-10 w-screen pointer-events-none px-container">
      <div className="flex relative z-20 justify-between py-4">
        <div className="pointer-events-auto">
          <LangugageSwitcher alternativesLanguages={alternativesLanguages} />
        </div>

        <div className="h-11 pointer-events-auto">
          <WaveButton onClick={handleButtonClick} isOpen={isOpen} />
        </div>
      </div>

      <motion.div
        animate={controls}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        className="flex fixed inset-y-0 left-full z-10 flex-col w-screen h-screen bg-paleOrange-600 pointer-events-auto md:py-20 md:pl-12 md:w-auto px-container md:pr-container"
      >
        <div className="text-center md:hidden">
          <div className="inline-block px-12 mt-20 mb-16 max-w-sm">
            <AubergeLogotype />
          </div>
        </div>

        <nav className="grow md:mr-32">
          <ul className="space-y-6 text-center md:text-left ">
            {menuItems.map((item) => (
              <li>
                <Link
                  to={item.href}
                  className={classNames(
                    'font-title whitespace-nowrap fluid-text-[1.25/2] hover:text-paleOrange-300 transition-colors',
                    styles.menuLink
                  )}
                  onClick={handleLinkClick}
                >
                  {item.label}
                </Link>

                {item.subNav && item.subNav.length > 0 && (
                  <ul className="">
                    {item.subNav.map((subItem) => (
                      <li>
                        <Link
                          to={subItem.href}
                          className={classNames([
                            'whitespace-nowrap fluid-text-[1/1.25] hover:text-paleOrange-300 transition-colors',
                            styles.subMenuLink,
                          ])}
                          onClick={handleLinkClick}
                        >
                          {subItem.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </motion.div>

      <div className="flex fixed right-0 bottom-0 z-20 flex-col items-stretch pt-2 pb-4 mx-auto space-y-2 w-screen md:items-end px-container">
        <a
          href="https://secure.reservit.com/fo/booking/58/203884?specialMode=default&langcode=FR&partid=0&custid=58&hotelid=203884&m=booking"
          className={classNames([
            'flex justify-center items-center py-4 w-full text-center uppercase align-middle rounded-full pointer-events-auto md:aspect-square md:w-32 md:h-32 bg-midnightGreen-700 text-paleOrange-700 text-sm hover:text-midnightGreen-700 hover:bg-paleOrange-300 transition-colors',
            styles.ctaButton,
          ])}
        >
          <span>
            <FormattedMessage
              defaultMessage="Reserver une{nbsp}chambre"
              values={{ nbsp: <>&nbsp;</> }}
            />
          </span>
        </a>

        <a
          href="https://booking.libroreserve.com/23ef236e8f8376e/QC016179900591?affiliate-code=WEB&restaurant-auto-select=true"
          className={classNames([
            'flex justify-center items-center py-4 w-full text-center uppercase align-middle rounded-full pointer-events-auto md:aspect-square md:w-32 md:h-32 text-midnightGreen-700 bg-paleOrange-300 text-sm hover:bg-midnightGreen-700 hover:text-paleOrange-700 transition-colors',
            styles.ctaButton,
          ])}
        >
          <span>
            <FormattedMessage
              defaultMessage="Reserver une{nbsp}table"
              values={{ nbsp: <>&nbsp;</> }}
            />
          </span>
        </a>
      </div>
    </header>
  );
}

export default SiteHeader;

SiteHeader.propTypes = {
  alternativesLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      href: PropTypes.string,
    })
  ),
};

SiteHeader.defaultProps = {
  alternativesLanguages: [
    { locale: 'en', href: '/en' },
    { locale: 'fr', href: '/' },
  ],
};
