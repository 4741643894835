// vendors
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { m as motion, useAnimation, useReducedMotion } from 'framer-motion';

const variants = {
  topWave: {
    animate: {
      x: 24,
      transition: {
        repeat: Infinity,
        repeatType: 'reverse',
        duration: 3,
        ease: 'easeInOut',
      },
    },
  },
  bottomWave: {
    animate: {
      x: -24,
      transition: {
        repeat: Infinity,
        repeatType: 'reverse',
        duration: 3,
        ease: 'easeInOut',
      },
    },
  },
};

function AubergePictogram({ className, animate }) {
  const shouldReduceMotion = useReducedMotion();
  const controls = useAnimation();

  React.useLayoutEffect(() => {
    if (!shouldReduceMotion && animate) {
      controls.start('animate');
    }
  }, [animate, shouldReduceMotion]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 153.5 248.5"
      className={classNames(['fill-current', className])}
    >
      <defs>
        <clipPath id="clip-path-top-wave">
          <path d="M30 183h93v16H30z" />
        </clipPath>
        <clipPath id="clip-path-bottom-wave">
          <path d="M54 202h46v16H54z" />
        </clipPath>
      </defs>

      <path d="M77 249c-43 0-77-56-77-125S34 0 77 0s76 56 76 124-34 125-76 125ZM77 3C36 3 3 57 3 124s33 122 74 122 73-55 73-122S117 3 77 3Z" />
      <path d="M76 74c-6 3-16 8-22 19-11 18-10 41 21 48v39h3v-39c8 2 18 2 29 1-12-22-17-28-39-34-13-4-6-20 8-29 24-15 25-24 22-30a7 7 0 0 0 7-3c-4 2-8 1-11-1a13 13 0 0 0-16 0L44 72l35-12c6-2 11 6-3 14Z" />
      <g style={{ clipPath: 'url(#clip-path-top-wave)' }}>
        <motion.path
          d="M147 193a14 14 0 0 1-10-3l-2-2-2 2a15 15 0 0 1-19 0l-2-2-2 2a15 15 0 0 1-20 0l-2-2-2 2a14 14 0 0 1-19 0l-2-2-2 2a15 15 0 0 1-19 0l-2-2-2 2a14 14 0 0 1-10 3 14 14 0 0 1-9-3l-3-2-2 2a14 14 0 0 1-9 3v3a18 18 0 0 0 11-4 18 18 0 0 0 12 4 18 18 0 0 0 12-4 18 18 0 0 0 23 0 18 18 0 0 0 23 0 18 18 0 0 0 24 0 18 18 0 0 0 23 0 18 18 0 0 0 12 4Z"
          animate={controls}
          variants={variants.topWave}
          initial={{ x: -24 }}
        />
      </g>

      <g style={{ clipPath: 'url(#clip-path-bottom-wave)' }}>
        <motion.path
          d="M123 210a14 14 0 0 1-9-3l-2-2-2 2a15 15 0 0 1-20 0l-2-2-2 2a14 14 0 0 1-19 0l-2-2-2 2a15 15 0 0 1-19 0l-2-2-2 2a14 14 0 0 1-10 3v4a18 18 0 0 0 12-5 18 18 0 0 0 23 0 18 18 0 0 0 23 0 18 18 0 0 0 24 0 18 18 0 0 0 11 5Z"
          animate={controls}
          variants={variants.bottomWave}
          initial={{ x: 24 }}
        />
      </g>
    </svg>
  );
}

AubergePictogram.propTypes = {
  className: PropTypes.string,
  animate: PropTypes.bool,
};

AubergePictogram.defaultProps = {
  className: null,
  animate: false,
};

export default AubergePictogram;
