import React from 'react';
import { m as motion, useViewportScroll, useTransform } from 'framer-motion';
import PropTypes from 'prop-types';

function offsetTop(element, acc = 0) {
  if (element.offsetParent) {
    return offsetTop(element.offsetParent, acc + element.offsetTop);
  }

  return acc;
}

function findInterserctionTop({
  clientHeight,
  elementHeight,
  elementTop,
  delta,
}) {
  return (
    (2 * clientHeight -
      delta * clientHeight +
      delta * elementHeight +
      2 * delta * elementTop -
      2 * elementTop) /
    (2 * (delta - 1))
  );
}

function Parallax({ children, delta, className, disable }) {
  const ref = React.useRef(null);
  const [elementY, setElementY] = React.useState(0);
  const [elementHeight, setElementHeight] = React.useState(0);
  const [elementTop, setElementTop] = React.useState(0);
  const [clientHeight, setClientHeight] = React.useState(0);

  const { scrollY } = useViewportScroll();

  const intersectionValue = findInterserctionTop({
    clientHeight,
    elementHeight,
    elementTop,
    delta,
  });

  const minValue =
    delta *
    (intersectionValue + clientHeight / 2 - (elementTop + elementHeight / 2));

  const y = useTransform(scrollY, (value) =>
    !disable
      ? Math.max(
          Math.min(
            delta * (value + clientHeight / 2 - elementY),
            minValue * -1
          ),
          minValue
        )
      : 0
  );

  React.useLayoutEffect(() => {
    const element = ref.current;

    const handleResize = () => {
      const top = offsetTop(element);
      setElementY(top + element.offsetHeight / 2);
      setElementTop(top);
      setElementHeight(element.offsetHeight);
      setClientHeight(window.innerHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return (
    <motion.div ref={ref} className={className} style={{ y }}>
      {children}
    </motion.div>
  );
}

Parallax.propTypes = {
  children: PropTypes.node.isRequired,
  delta: PropTypes.number,
  className: PropTypes.string,
  disable: PropTypes.bool,
};

Parallax.defaultProps = {
  delta: 0,
  className: '',
  disable: false,
};

export default Parallax;
