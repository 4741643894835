// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { m as motion } from 'framer-motion';

function InView({ children, className }) {
  return (
    <motion.div
      className={className}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.25 }}
      transition={{ duration: 0.6 }}
    >
      {children}
    </motion.div>
  );
}

InView.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

InView.defaultProps = {
  className: null,
};

export default InView;
