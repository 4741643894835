// vendors
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const classes = {
  1: 'text-3xl font-bold',
  2: 'font-title fluid-text-[3/8]',
  3: 'font-title fluid-text-[1.25/3] fluid-mb-[0.875/1.125]',
  4: 'font-semibold text-paleOrange-600 uppercase',
  5: 'font-semibold uppercase',
};

function Title({ children, level, tag, className }) {
  const Tag = tag || `h${level}`;

  const selectedClasses = classes[level];

  return (
    <Tag className={classNames([selectedClasses, className])}>{children}</Tag>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.number,
  tag: PropTypes.string,
  className: PropTypes.string,
};

Title.defaultProps = {
  level: 1,
  tag: null,
  className: null,
};

export default Title;
