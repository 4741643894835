// vendors
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

function OutlinedButton({
  children,
  tag,
  href,
  className,
  target,
  rel,
  type,
  to,
}) {
  let Tag = tag || 'button';

  if (!tag && href) Tag = 'a';

  if (!tag && to) Tag = Link;

  return (
    <Tag
      type={type}
      to={to}
      className={classNames([
        'py-[0.5em] px-[2em] font-title fluid-text-[1.25/2] text-center text-midnightGreen-700 rounded-full border border-midnightGreen-700 hover:bg-midnightGreen-700 hover:text-white transition-colors cursor-pointer',
        className,
      ])}
      target={target}
      rel={rel}
      href={href}
    >
      <span>{children}</span>
    </Tag>
  );
}

OutlinedButton.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  type: PropTypes.string,
  to: PropTypes.string,
};

OutlinedButton.defaultProps = {
  tag: null,
  className: null,
  href: null,
  target: null,
  rel: null,
  type: null,
  to: null,
};

export default OutlinedButton;
