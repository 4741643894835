// vendors
import classNames from 'classnames';
import React from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';

// Components
import AubergePictogram from '../../components/Logo/AubergePictogram';

// context
import { useLoadingContext } from '../../contexts/Loading';

function Loading() {
  const { isLoading } = useLoadingContext();

  return (
    <FocusLock autoFocus returnFocus disabled={!isLoading}>
      <RemoveScroll enabled={isLoading}>
        <div
          className={classNames([
            'flex fixed w-screen h-screen opacity-0 z-50 justify-center items-center text-paleOrange-600 bg-midnightGreen-700 pointer-events-none transition-opacity',
            { 'opacity-100 pointer-events-auto': isLoading },
          ])}
        >
          <AubergePictogram className="w-28" animate />
        </div>
      </RemoveScroll>
    </FocusLock>
  );
}

export default Loading;
