import React, { useRef } from 'react';
import { m as motion, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Carousel({ children, className }) {
  const controls = useAnimation();
  const slidesRef = useRef(null);
  const containerRef = useRef(null);

  // const elements = React.Children.toArray(children);
  //
  React.useEffect(() => {
    const handleItersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          controls.start(() => ({
            x: [1, -1 * slidesRef.current.scrollWidth],
            transition: {
              repeat: Infinity,
              repeatType: 'loop',
              duration: slidesRef.current.scrollWidth * 0.025,
              ease: 'linear',
            },
          }));
        } else {
          controls.stop();
        }
      });
    };

    const observer = new IntersectionObserver(handleItersection);

    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, [controls, slidesRef]);

  return (
    <div
      className={classNames(['overflow-hidden w-full', className])}
      ref={containerRef}
    >
      <motion.div className="flex will-change-transform" animate={controls}>
        <ul className="flex pr-4 space-x-4 h-max" ref={slidesRef}>
          {children}
        </ul>
        <ul className="flex space-x-4 h-max">
          {children}
          {children}
          {children}
        </ul>
      </motion.div>
    </div>
  );
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Carousel.defaultProps = {
  className: null,
};

export default Carousel;
