// vendors
import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../../views/Loading';
import SiteFooter from '../SiteFooter';
// Components
import SiteHeader from '../SiteHeader';

function Layout({ children, className, alternativesLanguages }) {
  return (
    <>
      <Loading />

      <SiteHeader alternativesLanguages={alternativesLanguages} />

      <main className={className}>{children}</main>

      <SiteFooter />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  alternativesLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      href: PropTypes.string,
    })
  ),
};

Layout.defaultProps = {
  className: null,
  alternativesLanguages: [
    { locale: 'en', href: '/en' },
    { locale: 'fr', href: '/' },
  ],
};

export default Layout;
