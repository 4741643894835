// vendors
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

export const classes =
  'underline transition-colors hover:text-paleOrange-600 cursor-pointer';

function UnderlinedButton({ children, tag, href, className, target, rel, to }) {
  let Tag = tag || 'button';

  if (!tag && href) Tag = 'a';

  if (!tag && to) Tag = Link;

  return (
    <Tag
      href={href}
      to={to}
      className={classNames(['inline-block', classes, className])}
      target={target}
      rel={rel}
    >
      {children}
    </Tag>
  );
}

UnderlinedButton.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  to: PropTypes.string,
};

UnderlinedButton.defaultProps = {
  tag: null,
  className: null,
  href: null,
  target: null,
  rel: null,
  to: null,
};

export default UnderlinedButton;
