// vendors
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AprilPictogram from '../Logo/AprilPictogram';
import AubergePictogram from '../Logo/AubergePictogram';

import wavePart from '../../images/wavePart.svg';
import OutlinedButton from '../Button/OutlinedButton';
import Title from '../Title/Title';
import UnderlinedButton from '../Button/UnderlinedButton';

function SiteFooter() {
  const intl = useIntl();
  return (
    <footer className="relative bg-paleOrange-100">
      <div className="absolute inset-x-0 -translate-y-1/2">
        <div className="container mx-auto">
          <div className="flex flex-wrap gap-x-9 justify-between font-title bg-paleOrange-600 fluid-py-[1.25/5] fluid-px-[1.5/4] fluid-text-[1.25/2]">
            <p className="grow text-center">
              <FormattedMessage defaultMessage="Pour ne rien manquer, abonnez-vous à notre infolettre!" />
            </p>

            <form
              action="https://hotmail.us6.list-manage.com/subscribe/post?u=8d1b9ecec3a9c78670e77f369&amp;id=f8f7550b07"
              method="post"
              target="_blank"
              className="flex grow border-b border-current max-w-"
            >
              <input
                type="email"
                name="EMAIL"
                placeholder="E-mail"
                id="newsletter-email"
                className="grow placeholder:text-inherit bg-inherit"
              />

              <button type="submit">→</button>
            </form>
          </div>
        </div>
      </div>

      <div className="container pb-32 mx-auto md:pb-1 fluid-pt-[4/8]">
        <div
          className="font-title text-center scroll-mt-16 fluid-my-[4/8] fluid-text-[1.25/2.25]"
          id={intl.formatMessage({ defaultMessage: 'nous-joindre' })}
        >
          <Title level={2} className="fluid-my-[4/8]">
            <FormattedMessage defaultMessage="Nous joindre" />
          </Title>

          <div className="fluid-my-[4/8]">
            <p>
              363, route du Fleuve, Notre-Dame-du-Portage,
              (Québec)&nbsp;G0L&thinsp;1Y0
            </p>

            <UnderlinedButton
              href="https://goo.gl/maps/hoMpueyge8F9VKGv7"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage defaultMessage="Voir la carte" />
            </UnderlinedButton>
          </div>

          <div className="fluid-my-[4/8]">
            <p>
              <FormattedMessage defaultMessage="Téléphone" />
              :&nbsp;<a href="tel:+14188620642">418-862-0642</a> /{' '}
              <FormattedMessage
                defaultMessage="Sans{nbsp}frais"
                values={{ nbsp: <>&nbsp;</> }}
              />
              :&nbsp;<a href="tel:+18886220642">1-888-622-0642</a>
            </p>

            <UnderlinedButton href="mailto:info@aubergesurmer.ca">
              info@aubergesurmer.ca
            </UnderlinedButton>
          </div>

          <div className="fluid-my-[4/8]">
            <OutlinedButton href="mailto:info@aubergesurmer.ca">
              <FormattedMessage defaultMessage="Un emploi avec nous" />
            </OutlinedButton>
          </div>
        </div>

        <hr
          className="block w-full h-2 bg-repeat-x border-none opacity-10"
          style={{
            backgroundImage: `url(${wavePart})`,
          }}
        />

        <div className="flex my-16">
          <div className="flex-1 space-y-4 lg:flex lg:items-center">
            <AubergePictogram className="w-14 lg:mr-10" animate />

            <a
              className="block leading-none lg:mr-20 fluid-text-[0.875/1.125]"
              href="https://www.instagram.com/auberge_sur_mer/"
              target="_blank"
              rel="noreferrer"
            >
              <b className="font-semibold uppercase fluid-text-[0.652/0.75]">
                Instagram
              </b>
              <br />
              auberge_sur_mer
            </a>

            <a
              className="block leading-none fluid-text-[0.875/1.125]"
              href="https://www.facebook.com/aubergesurmer"
              target="_blank"
              rel="noreferrer"
            >
              <b className="font-semibold uppercase fluid-text-[0.652/0.75]">
                Facebook
              </b>
              <br />
              aubergesurmer
            </a>
          </div>

          <div className="flex-1 space-y-4 lg:flex lg:items-center">
            <AprilPictogram className="w-14 lg:mr-10" />

            <a
              className="block leading-none lg:mr-20 fluid-text-[0.875/1.125]"
              href="https://www.instagram.com/april.bistro.de.saison/"
              target="_blank"
              rel="noreferrer"
            >
              <b className="font-semibold uppercase fluid-text-[0.652/0.75]">
                Instagram
              </b>
              <br />
              april.bistro.de.saison
            </a>

            <a
              className="block leading-none fluid-text-[0.875/1.125]"
              href="https://www.facebook.com/April-bistro-de-saison-107016148249924/"
              target="_blank"
              rel="noreferrer"
            >
              <b className="font-semibold uppercase fluid-text-[0.652/0.75]">
                Facebook
              </b>
              <br />
              april.bistro.de.saison
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default SiteFooter;
