// vendors
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { m as motion } from 'framer-motion';
import { useIntl } from 'react-intl';

const animationTransition = {
  repeat: Infinity,
  repeatType: 'reverse',
  ease: 'easeInOut',
  duration: 3,
};

const animationVariants = {
  oddWave: {
    initial: {
      x: 14,
    },
    hover: {
      x: -14,
      transition: animationTransition,
    },
  },
  evenWave: {
    initial: {
      x: -14,
    },
    hover: {
      x: 14,
      transition: animationTransition,
    },
  },
  label: {
    hover: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.8,
      },
    },
    initial: {
      opacity: 0,
      x: 10,
      transition: {
        duration: 0.8,
      },
    },
  },
};

function WaveButton({ onClick, className, isOpen }) {
  const intl = useIntl();
  return (
    <motion.button
      type="button"
      onClick={onClick}
      whileHover="hover"
      className={classNames(['flex items-center', className])}
    >
      <motion.span
        className="mr-2"
        initial={animationVariants.label.initial}
        variants={animationVariants.label}
      >
        {isOpen ? intl.formatMessage({ defaultMessage: 'fermer' }) : 'menu'}
      </motion.span>

      <div className="relative w-11 h-11 fill-current">
        {!isOpen && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 44 44"
            className="absolute inset-0 fill-current"
          >
            <defs>
              <clipPath id="clip-path">
                <rect x="1" y="7" width="42" height="30" />
              </clipPath>
            </defs>
            <g style={{ clipPath: 'url(#clip-path)' }}>
              <motion.path
                variants={animationVariants.oddWave}
                initial={animationVariants.oddWave.initial}
                d="M58.09,37a11.19,11.19,0,0,1-7.21-2.53,11.57,11.57,0,0,1-14.44,0,11.57,11.57,0,0,1-14.44,0A11.22,11.22,0,0,1,14.78,37a11.24,11.24,0,0,1-7.22-2.53A11.22,11.22,0,0,1,.34,37a11.24,11.24,0,0,1-7.22-2.53A11.19,11.19,0,0,1-14.09,37V35.4a9.69,9.69,0,0,0,6.66-2.58l.55-.53.56.53A9.58,9.58,0,0,0,.34,35.4,9.59,9.59,0,0,0,7,32.82l.55-.53.56.53a9.56,9.56,0,0,0,6.66,2.58,9.61,9.61,0,0,0,6.67-2.58l.55-.53.55.53a9.9,9.9,0,0,0,13.33,0l.56-.53.55.53a9.9,9.9,0,0,0,13.33,0l.56-.53.55.53a9.58,9.58,0,0,0,6.66,2.58Z"
              />
              <motion.path
                variants={animationVariants.evenWave}
                initial={animationVariants.evenWave.initial}
                d="M58.09,28.57A11.19,11.19,0,0,1,50.88,26a11.57,11.57,0,0,1-14.44,0A11.57,11.57,0,0,1,22,26a11.22,11.22,0,0,1-7.22,2.53A11.24,11.24,0,0,1,7.56,26,11.22,11.22,0,0,1,.34,28.57,11.24,11.24,0,0,1-6.88,26a11.19,11.19,0,0,1-7.21,2.53V27a9.69,9.69,0,0,0,6.66-2.58l.55-.53.56.53A9.58,9.58,0,0,0,.34,27,9.59,9.59,0,0,0,7,24.39l.55-.53.56.53A9.56,9.56,0,0,0,14.78,27a9.61,9.61,0,0,0,6.67-2.58l.55-.53.55.53a9.9,9.9,0,0,0,13.33,0l.56-.53.55.53a9.9,9.9,0,0,0,13.33,0l.56-.53.55.53A9.58,9.58,0,0,0,58.09,27Z"
              />
              <motion.path
                variants={animationVariants.oddWave}
                initial={animationVariants.oddWave.initial}
                d="M58.09,20.14a11.19,11.19,0,0,1-7.21-2.53,11.57,11.57,0,0,1-14.44,0,11.57,11.57,0,0,1-14.44,0,11.22,11.22,0,0,1-7.22,2.53,11.24,11.24,0,0,1-7.22-2.53A11.22,11.22,0,0,1,.34,20.14a11.24,11.24,0,0,1-7.22-2.53,11.19,11.19,0,0,1-7.21,2.53v-1.6A9.69,9.69,0,0,0-7.43,16l.55-.53.56.53A9.58,9.58,0,0,0,.34,18.54,9.59,9.59,0,0,0,7,16l.55-.53.56.53a9.56,9.56,0,0,0,6.66,2.58A9.61,9.61,0,0,0,21.45,16l.55-.53.55.53a9.9,9.9,0,0,0,13.33,0l.56-.53L37,16a9.9,9.9,0,0,0,13.33,0l.56-.53.55.53a9.58,9.58,0,0,0,6.66,2.58Z"
              />
              <motion.path
                variants={animationVariants.evenWave}
                initial={animationVariants.evenWave.initial}
                d="M58.09,11.71a11.19,11.19,0,0,1-7.21-2.53,11.24,11.24,0,0,1-7.22,2.53,11.22,11.22,0,0,1-7.22-2.53A11.57,11.57,0,0,1,22,9.18a11.22,11.22,0,0,1-7.22,2.53A11.24,11.24,0,0,1,7.56,9.18,11.22,11.22,0,0,1,.34,11.71,11.24,11.24,0,0,1-6.88,9.18a11.19,11.19,0,0,1-7.21,2.53v-1.6A9.69,9.69,0,0,0-7.43,7.53L-6.88,7l.56.53A9.58,9.58,0,0,0,.34,10.11,9.59,9.59,0,0,0,7,7.53L7.56,7l.56.53a9.56,9.56,0,0,0,6.66,2.58,9.61,9.61,0,0,0,6.67-2.58L22,7l.55.53a9.72,9.72,0,0,0,6.67,2.58,9.67,9.67,0,0,0,6.66-2.58L36.44,7l.55.53a9.59,9.59,0,0,0,6.67,2.58,9.58,9.58,0,0,0,6.66-2.58L50.88,7l.55.53a9.58,9.58,0,0,0,6.66,2.58Z"
              />
            </g>
          </svg>
        )}

        {isOpen && <span className="text-4xl">❌</span>}
      </div>
    </motion.button>
  );
}

WaveButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

WaveButton.defaultProps = {
  className: null,
  onClick: () => {},
  isOpen: false,
};

export default WaveButton;
