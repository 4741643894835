import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

function LangugageSwitcher({ alternativesLanguages }) {
  const { locale } = useIntl();
  let alternativeLangTag = 'en';
  let label = 'English';

  switch (locale) {
    case 'en':
      alternativeLangTag = 'fr';
      label = 'Français';
      break;

    default:
      alternativeLangTag = 'en';
      label = 'English';
      break;
  }

  // eslint-disable-next-line react/prop-types
  const localeObject = alternativesLanguages.find(
    (obj) => obj.locale === alternativeLangTag
  );

  return (
    <a href={localeObject.href} hrefLang={alternativeLangTag}>
      {label}
    </a>
  );
}

export default LangugageSwitcher;

LangugageSwitcher.propTypes = {
  alternativesLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      href: PropTypes.string,
    })
  ),
};

LangugageSwitcher.defaultProps = {
  alternativesLanguages: [
    { locale: 'en', href: '/en' },
    { locale: 'fr', href: '/' },
  ],
};
