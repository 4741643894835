// vendors
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Text({ children, className }) {
  return (
    <div
      className={classNames([
        'space-y-[1em] fluid-text-[0.875/1.125]',
        className,
      ])}
    >
      {children}
    </div>
  );
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Text.defaultProps = {
  className: null,
};

export default Text;
